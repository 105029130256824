define("ember-lifeline/mixins/dom", ["exports", "@ember/object/mixin", "@ember/debug", "ember-lifeline/dom-event-listeners"], function (_exports, _mixin, _debug, _domEventListeners) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * ContextBoundEventListenersMixin provides a mechanism to attach event listeners
   * with runloops and automatic removal when the host object is removed from DOM.
   *
   * @class ContextBoundEventListenersMixin
   * @public
   */
  var _default = _mixin.default.create({
    init() {
      this._super(...arguments);
      (false && !(false) && (0, _debug.deprecate)("ember-lifeline ContextBoundEventListenersMixin is deprecated. Please use the functional equivalent of this mixin's methods instead.", false, {
        id: 'ember-lifeline-deprecated-context-bound-event-listeners-mixin',
        until: '7.0.0',
        for: 'ember-lifeline',
        since: {
          enabled: '6.0.0'
        }
      }));
    },
    /**
     * Attaches an event listener that will automatically be removed when the host
     * object is dropped from DOM.
     *
     * Example:
     *
     * ```js
     * import Component from 'ember-component';
     * import ContextBoundEventListenersMixin from 'ember-lifeline';
     *
     * export default Component.extend(ContextBoundEventListenersMixin, {
     *   didInsertElement() {
     *     this.addEventListener('.some-item', 'click', (e) => {
     *       console.log('.some-item was clicked');
     *     });
     *   }
     * });
     * ```
     *
     * This can also be used in other ember types like services and controllers. In
     * order to use it there an html element reference must be used instead of a
     * css selector. This way we can be sure the element actually exists when the
     * listener is attached:
     *
     * ```js
     * import Service from 'ember-service';
     * import ContextBoundEventListenersMixin from 'ember-lifeline';
     *
     * export default Service.extend(ContextBoundEventListenersMixin, {
     *   init() {
     *     this._super(...arguments);
     *     const el = document.querySelector('.foo');
     *     this.addEventListener(el, 'click')
     *   }
     * });
     * ```
     *
     * @method addEventListener
     * @param { String | EventTarget } target the DOM selector or element
     * @param { String } _eventName the event name to listen for
     * @param { Function } _callback the callback to run for that event
     * @public
     */
    addEventListener(target, eventName, callback, options) {
      (false && !(!this.isComponent || this.tagName !== '' || typeof target !== 'string') && (0, _debug.assert)('Must provide an element (not a DOM selector) when using addEventListener in a tagless component.', !this.isComponent || this.tagName !== '' || typeof target !== 'string'));
      (false && !(!this.isComponent || typeof target !== 'string' || this._currentState === this._states.inDOM) && (0, _debug.assert)('Called addEventListener with a css selector before the component was rendered', !this.isComponent || typeof target !== 'string' || this._currentState === this._states.inDOM));
      (false && !(this.isComponent || typeof target !== 'string') && (0, _debug.assert)('Must provide an element (not a DOM selector) when calling addEventListener outside of component instance.', this.isComponent || typeof target !== 'string'));
      let element;

      // If no element is provided, we assume we're adding the event listener to the component's element. This
      // addresses use cases where we want to bind events like `scroll` to the component's root element.
      if (this.isComponent && typeof eventName === 'function') {
        options = callback;
        callback = eventName;
        eventName = target;
        element = this.element;
      } else {
        element = findElement(this.element, target);
      }
      (0, _domEventListeners.addEventListener)(this, element, eventName, callback, options);
    },
    /**
     * @method removeEventListener
     * @param { String | EventTarget } target the DOM selector or element
     * @param { String } _eventName the event name to listen for
     * @param { Function } callback the callback to run for that event
     * @public
     */
    removeEventListener(target, eventName, callback, options) {
      let element;

      // If no element is provided, we assume we're adding the event listener to the component's element. This
      // addresses use cases where we want to bind events like `scroll` to the component's root element.
      if (this.isComponent && typeof eventName === 'function') {
        callback = eventName;
        eventName = target;
        element = this.element;
      } else {
        element = findElement(this.element, target);
      }
      (0, _domEventListeners.removeEventListener)(this, element, eventName, callback, options);
    }
  });
  _exports.default = _default;
  function findElement(contextElement, selector) {
    let selectorType = typeof selector;
    let element;
    if (selectorType === 'string') {
      let maybeElement = contextElement.querySelector(selector);
      if (maybeElement === null) {
        throw new Error(`Called addEventListener with selector not found in DOM: ${selector}`);
      }
      element = maybeElement;
    } else if (selector instanceof Element && selector.nodeType || selector instanceof Window) {
      element = selector;
    }
    return element;
  }
});